<template>
  <div class="sign-in">
    <div class="sign-left">
      <signInUpLeft />
    </div>
    <div class="sign-right">
      <div class="sign-wrap">
        <h3>Sign In</h3>
        <div class="to-sign-up">
          Don’t have an account yet? <a class="link" href="#/sign-up">Join ArtemisAds</a>
        </div>
        <a-form>
          <a-form-item 
            v-bind="validateInfos.email"
          >
            <div class="sign-input">
            <span>Email Address</span>
            <a-input v-model:value="modelRef.email" style="height: 40px;"/>
          </div>
          </a-form-item>
        </a-form>
        <a-form-item 
          v-bind="validateInfos.password"
        >
          <div class="password-input">
            <span>Password</span>
            <a-input-password v-model:value="modelRef.password" style="height: 40px;"/>
          </div>
        </a-form-item>
        <div class="submit-btn">
          <a-form-item>
            <button type="primary" html-type="submit" @click="onSignIn">Sign In</button>
          </a-form-item>
        </div>
        <div class="bottom-text">
          By joining or signing in, you agree to our <a>Terms of Service</a> and <a>Privacy Policy</a>
        </div>
      </div>
    </div>
    <contextHolder />
  </div>
</template>
<script setup>

  import { reactive } from 'vue';
  import { signApi } from '../../server/index'
  import jwt from 'jsonwebtoken';
  import { Form, notification } from 'ant-design-vue';

  import { useUserInfoStore } from '../../storeData/index';

  import signInUpLeft from '../../components/signInUpLeft.vue';

  const store = useUserInfoStore();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Warning`,
      description: description,
      placement: 'topRight',
      duration: 10
    });
  };

  const useForm = Form.useForm;
  const modelRef = reactive({
    email: '',
    password: '',
  });

  const rulesRef = reactive({
    email: [{
      required: true,
      message: 'Invalid email',
      type: 'email',
    }],
    password: [{
      required: true,
      message: 'Password cannot be empty',
    }]
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

  async function onSignIn () {
    const validateRes = await validate();
    if (!validateRes.errorFields) {
      const params = validateRes;
      const res = await signApi.signIn(params);
      const { data, success, error } = res;
      if (success) {
        let href = '/#/admin/welcome';
        const userToken = data.token;
        const userInfo = jwt.decode(userToken);
        store.setUserInfo(userInfo, userToken);
        if (userInfo.status === 'PENDING_CHOOSE_TYPE') {
          href = '/#/profile';
        }
        location.href = href;
      } else {
        openNotification(error)
      }
    }
  }
</script>
<style lang="less" scoped>
@media screen and (min-width: 960px) {
  .sign-in {
    width: 100dvw;
    height: 100dvh;
    min-height: 800px;
    overflow: hidden;
    display: flex;
  }
  .sign-right {
    display: flex;
    justify-content: center;
    background: #F4F2FA;
    flex: 1;
  }
  .sign-wrap {
    padding: 48px 40px 50px 40px;
    width: 480px;
    height: 520px;
    min-height: 520px;
    margin-top: 140px;
    background: #FFFFFF;
    box-shadow: 0 1px 0 0 #1a16250d;
    border-radius: 16px;
    > h3 {
      width: 102px;
      height: 36px;
      font-family: Sora-Medium;
      font-weight: 500;
      font-size: 28px;
      color: #0F0629;
      letter-spacing: 0.5px;
      line-height: 36px;
    }
    .to-sign-up {
      color: #6a647d;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      text-align: left;
      margin-top: 8px;
      > a {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: left;
      }
    }
    .password-input {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      > span {
        opacity: 0.7;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
        input {
          height: 40px;
        }
      }
      
    }
    .sign-input {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      > span {
        height: 16px;
        opacity: 0.7;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    .submit-btn {
      margin-top: 32px;
      button {
        width: 400px;
        height: 40px;
        background: #7D58E5;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 20px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
    .bottom-text {
      margin-top: 58px;
      color: #0f0629;
      color: #0f0629;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-align: left;
      > a {
        text-decoration: underline;
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .sign-in {
    width: 100dvw;
    height: 100dvh;
    overflow: hidden;
    display: flex;
  }
  .sign-right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .sign-wrap {
      align-self: center;
      position: relative;
      z-index: 1;
      width: calc(100dvw - 32px);
      padding: 24px 32px;
      width: 343px;
      background: #FFFFFF;
      box-shadow: 0 1px 0 0 #1a16250d;
      border-radius: 16px;
    }
    > h3 {
      font-family: Sora-Medium;
      font-weight: 500;
      font-size: 28px;
      color: #0F0629;
      letter-spacing: 0.5px;
      line-height: 38px;
    }
    .to-sign-up {
      color: #6a647d;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.4px;
      text-align: left;
      margin-top: 8px;
      > a {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: left;
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    .password-input {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      > span {
        opacity: 0.7;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        &:first-child {
          margin-bottom: 8px;
        }
        input {
          height: 40px;
        }
      }
    }
    .sign-input {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      > span {
        height: 16px;
        opacity: 0.7;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    .submit-btn {
      margin-top: 40px;
      button {
        width: 100%;
        height: 40px;
        background: #7D58E5;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 20px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        text-align: center;
      }
    }
    .bottom-text {
      margin-top: 40px;
      color: #0f0629;
      color: #0f0629;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-align: left;
      > a {
        text-decoration: underline;
      }
    }
  }
}

</style>
